import React, {useState} from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { globalHistory  } from "@reach/router"
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

export default function NavBar( {navScroll}) {
    const data = useStaticQuery(graphql`
        query NavSiteQuery {
            site {
                siteMetadata {
                    title
                    menuLinks {
                        name
                        link
                        subMenu {
                            name
                            link
                        }
                    }
                }
            }
        }
    `)

    const [toggle, setToggle] = useState(false)
    const menu = data.site.siteMetadata.menuLinks
    const fullPath = globalHistory.location.pathname
    const path = fullPath.split("/")

    const splitPath = (link) => {
        return link.split('/')[1].split('-')[0]
    }

    const toggleOnClick = () => {
        setToggle(!toggle)
    }

    const navBg = () => {
        if(path.length > 3) {
            return "primary"
        }
        else {
            if(toggle) {
                return "primary"
            }
            else {
                if(navScroll) {
                    return "primary"
                }
                else {
                    return "transparent"
                }
            }
        }
    }

    // Make partial paths active
    // Ex. If current path is /news/blog-1/ then we want News link to be active
    const isPartiallyActive = (className, link) => ({ location }) => {
        if ( splitPath(location.pathname) === splitPath(link) ) {
            className += ' active'
        }
        return { className: className }
    }

    const dropdownTitle = item => {
        let style = {}
        for(let sub of item.subMenu) {
            let subLink = sub.link.split('/')[1]
            if(subLink === path[1]) {
                style.color = "#FFC000"
            }
        }

        return (<span style={style}>
            {item.name.toUpperCase()}
            <FontAwesomeIcon icon={faAngleDown} className="ml-2"/>
        </span>)
    }

    const navLinks = menu.map( (item) => {
        if ( item.subMenu && item.subMenu.length > 0 ) {
            return (
                <NavDropdown
                    title={dropdownTitle(item)}
                    id="nav-dropdown"
                    key={item.name}
                >
                    {
                        item.subMenu.map( (sub) => (
                            <Link
                                to={ sub.link }
                                key={ sub.link }
                                getProps={ isPartiallyActive('dropdown-item', sub.link) }
                            >
                                { sub.name }
                            </Link>
                        ))
                    }
                </NavDropdown>
            )
        } else {
            if ( item.name === "Contact Us" ) {
                return (
                    <Link
                        to={ item.link }
                        key={ item.link }
                        className="btn btn-contact"
                    >
                        { item.name.toUpperCase() }
                    </Link>
                )
            } else {
                return (
                    <Link
                        to={ item.link }
                        key={ item.link }
                        getProps={ isPartiallyActive('nav-link', item.link) }
                    >
                        { item.name.toUpperCase() }
                    </Link>
                )
            }
        }
    })

    return (
        <Navbar bg={navBg()} expand="lg" className="navbar-dark sticky-top"
            style={{width: "100%", flexWrap: "wrap"}}>
            <Navbar.Brand href="/">
                <img
                    alt="Trabus logo"
                    src="/logos/trabus_logo_white.svg"
                    height={35}
                    className="d-none d-lg-block align-top"
                />{' '}
                <img
                    alt="Trabus logo"
                    src="/logos/trabus_logo_short_white.svg"
                    height={35}
                    className="d-lg-none align-top"
                />{' '}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav" onClick={toggleOnClick}/>
            <Navbar.Collapse className="navbar-toggleable-md" id="navbar-nav">
                <Nav className="ml-auto">
                    { navLinks }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

